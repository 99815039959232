body {
  margin: 0;
  padding: 0;
}
header a {
  color: #3789cb;
}
header nav ul li ul {
  background: #000;
  box-shadow: var(--box-shadow) #666;
  border: 0;
}
.desc {
  font-size: 12px;
}
.row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-nav {
  position: absolute;
}
.header-bg {
  width: 100%;
  object-fit: cover;
  object-position: center bottom;
  height: 100vh;
  display: block;
  opacity: 0.9;
}
.slogan {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -101px;
  width: 202px;
  z-index: 3;
}
.slogan.en {
  margin-left: -201px;
  width: 402px;
}
.index-scroll {
  position: absolute;
  bottom: 50px;
  left: 50%;
  margin-left: -37px;
  z-index: 3;
  display: block;
  padding: 30px;
}
.header-index {
  position: absolute;
  width: 86%;
  left: 7%;
  right: 7%;
  top: 0;
  z-index: 2;
}
.header-layout {
  background: #000;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.header-link {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #000;
  padding: 30px;
  box-sizing: border-box;
}
.header-link a {
  display: flex;
  flex-direction: column;
  color: #3789cb;
  align-items: center;
  font-weight: 300;
}
.header-link a img {
  margin-bottom: 15px;
}
.index-block {
  text-align: center;
  color: #4f5257;
  padding: 40px 0;
  background: #f5f5f5;
  border-bottom: 1px solid #fff;
}
.index-desc {
  padding: 10px 70px;
  font-size: 12px;
  line-height: 2.4em;
}
.footer-form-title {
  margin: 25px 0 15px 0;
  font-size: 15px;
}
.footer {
  background: #000 url("3a815687ff1e92fd1b87be47ad22c859.jpg") no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  overflow: hidden;
}
.footer a {
  color: #fff;
}
.footer input {
  width: 70%;
  max-width: 700px;
  height: 37px;
  border-radius: 0;
  display: block;
  margin: 0 auto 10px auto;
  text-align: center;
  border-left: 3px solid #3789cb;
  box-sizing: border-box;
}
.footer input:focus {
  outline: 0;
}
.footer input::-webkit-input-placeholder {
  color: #ccc;
}
.footer button {
  width: 70%;
  max-width: 700px;
  height: 42px;
  line-height: 42px;
  padding: 0;
  border-radius: 0;
  color: #000;
  font-weight: 300;
}
.footer button:focus {
  outline: 0;
}
.contact {
  padding: 25px 0 0 0;
}
.copyright {
  font-size: 12px;
  margin-top: 30px;
}
.qr-img {
  display: block;
  margin: 15px auto 0 auto;
}
.content-header {
  background: #000 url("f7c2ef7156d38116efe18c0310b82740.jpg") no-repeat center 40px;
  background-size: contain;
  overflow: hidden;
  color: #fff;
}
.content-header h2 {
  color: #3789cb;
  font-size: 20px;
}
.content-header aside {
  color: #fff;
  background: #181818;
  border-radius: 6px;
  padding: 16px 30px;
  margin: 100px 15px 30px 15px;
  font-size: 12px;
}
aside {
  padding: 20px;
  color: #4f5257;
}
aside p {
  font-size: 15px;
}
aside p img {
  max-width: 100%;
  display: block;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
aside h3 {
  height: 40px;
  line-height: 40px;
  margin-bottom: 15px;
  font-size: 20px;
  position: relative;
}
aside h3:after {
  content: ' ';
  display: block;
  position: absolute;
  height: 3px;
  width: 30px;
  background: #3789cb;
}
aside.dark {
  color: #fff;
  background-color: #000;
}
aside.texture {
  background-image: url("3a815687ff1e92fd1b87be47ad22c859.jpg");
  background-repeat: no-repeat;
  background-position: left top;
}
.contrast {
  width: 100%;
  position: relative;
}
.contrast img {
  width: 100%;
  display: block;
}
.contrast .contrast-cover {
  height: 100%;
  width: 50%;
  overflow: hidden;
  position: absolute;
  background-size: auto 100%;
}
.contrast .contrast-control {
  position: absolute;
  height: 100%;
  width: 2px;
  background: #fff;
  left: 50%;
  top: 0;
  z-index: 2;
}
.contrast .contrast-control:after {
  content: ' ';
  position: absolute;
  width: 32px;
  height: 32px;
  background: #fff url("cf8792571f8de8ac79998c094bc93d73.png") no-repeat center center;
  border-radius: 50%;
  left: -16px;
  top: 45%;
}
.icon-mr {
  margin-right: 10px;
}
.animation.inplace {
  opacity: 0.5;
  transform: translate(0, 100px);
}
@-moz-keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(30px, 30px);
  }
}
@-webkit-keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(30px, 30px);
  }
}
@-o-keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(30px, 30px);
  }
}
@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(30px, 30px);
  }
}
